<template>
    <div class="LessonList" >
        <div class="nav">
            <van-row>
                <van-col span="4">
                    <div @click="logout">
                        <img class="lang-img" :src="common.getRes('logout.png')">
                    </div>
                </van-col>
                <van-col span="16">
                    {{lang.class_list_nav}}
                </van-col>
                <van-col span="4">
                    
                    <div @click="toggleLanguage">
                        <img class="lang-img" :src="lang.langBtn">
                    </div>
                </van-col>
            </van-row>
        </div>
        <div class="day" @click="clickDate">
            <img class="icon" :src="common.getRes('date.png')"/> {{dayStr}} <img class="icon" :src="common.getRes('more.png')"/>
        </div>
        <div class="content">
            <template v-if="lessonList.length>0">
                <!--<van-row>
                    <van-col offset="1" span="22">
                        <div class="row-str">ISA Science City</div>
                    </van-col>
                </van-row>-->
                <van-list
                    v-model:loading="loading"
                    :finished="finished"
                    :finished-text="lang.noMore"
                    @load="onLoad"
                >
                    <template v-for="item in lessonList" :key="item">
                        <van-row>
                            <van-col offset="1" span="22">
                                <div class="class-item" @click="clickClass(item)">
                                    <div class="color-line" :style="`background-color:${getColor(index)}`"></div>
                                    <div class="class-content">
                                        <div class="class-content-info">
                                            <div class="class-content-name">{{item.course.en_name}}</div>
                                            <div class="class-content-name">{{item.course.name}}</div>
                                            <div class="marginTop">
                                                <van-row>
                                                    <van-col span="18">
                                                        <span class="class-info">{{lang.time}}:{{item.course.kernel.start_time.substr(0,5)}} - {{item.course.kernel.end_time.substr(0,5)}} {{week}}</span>
                                                    </van-col>
                                                </van-row>
                                                <van-row>
                                                    <van-col span="18">
                                                        <span class="class-info">{{lang.place}}:{{item.course.kernel.venue.detail}}</span>
                                                    </van-col>
                                                </van-row>
                                                <van-row>
                                                    <van-col span="18">
                                                        <span class="class-info">{{lang.classAttendance}}: {{item.ontime_count}}/{{item.total_student_count}}</span>
                                                        <span class="class-info marginLeft" >{{lang.late}}:{{item.late_count}}</span>
                                                    </van-col>
                                                </van-row>
                                            </div>                     
                                        </div>
                                        <div class="class-content-btn">
                                            <div class="roll-call" :style="`background:url(${common.getRes(item.finished? 'btn-y.png' :'btn.png')});background-repeat: no-repeat;background-position-x: center;background-position-y: center;background-size: 100% 100%;`">
                                                {{item.finished ? lang.after_class : lang.roll_call}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </van-col>
                        </van-row>
                    </template>
                </van-list>
            </template>
            <template v-else>
                <van-row justify="center" align="center" style="height:100%">
                    <van-empty 
                        :image="common.getRes('null.png')"
                        :description="lang.no_courses" 
                    />
                </van-row>
            </template>
        </div>
        <footer>
            <van-row align="center" style="height:100%;">
                <van-col offset="1" span="21">
                    <div class="form-button" @click="createLesson">{{lang.createLesson}}</div>
                </van-col>
            </van-row>
        </footer>
        <van-action-sheet
            v-model:show="showSheet"
            :actions="actions"
            :cancel-text="lang.cancel"
            close-on-click-action
            @select="selectSheet"
        />

        <van-calendar 
            :title="lang.selectDate"
            :min-date="new Date(2021, 0, 1)"
            v-model:show="showDate" 
            @confirm="chooseDate"
            first-day-of-week="1"
            :confirm-text="lang.determine"
        />
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import Common from "../../library/Common";
import { useRouter } from 'vue-router';
import request from '../../library/MyRequest';
import Page from "../../library/Page";
import { Dialog } from 'vant';
import "@/css/form.css";
import "@/css/course.css";

export default {
    name: 'LessonList',
    setup(){
        const minRow = 6;
        const common = Common;
        const router = useRouter();
		common.chechLogin(router);
		
        var page = null;
        let state = reactive({
            lang:{
                class_list_nav:"",
                roll_call:"",
                langBtn:"",
                no_courses:"",
                exit_login:"",
                cancel:"",
                determine:"",
                createLesson:"",
                time:"",
                place:"",
                classAttendance:"",
                late:'',
                noMore:"",
                after_class:"",
                class_evaluation:"",
                personal_evaluation:"",
                upload_job:"",
                selectDate:"",
            },
            showSheet:false,
            showDate:false,
            currentDate:new Date(),
            week:"",
            dayStr:"",
            colors:["#E67E7E","#7EBDE6"],
            
            currentItem:null,
            loading:true,
            finished:false,
            lessonList:[],
            actions:[
                { name: '', id:"1" },
                { name: '', id:"2" },
                { name: '', id:"3" },
            ]
        });
    
        common.initLanguage(state.lang);
        state.lang.langBtn = common.getLanguageBtn();
        state.dayStr = common.getFormatDate(state.currentDate);
        state.week = common.getWeek(state.currentDate.getDay());

        const initSheet = function(){
            state.actions[0].name = state.lang.class_evaluation;
            state.actions[1].name = state.lang.personal_evaluation;
            state.actions[2].name = state.lang.upload_job;
        }

        initSheet();
        const toggleLanguage = function(){
            common.toggleLanguage();
            common.initLanguage(state.lang);
            state.week = common.getWeek(state.currentDate.getDay());
            state.lang.langBtn = common.getLanguageBtn();
            state.dayStr = common.getFormatDate(state.currentDate);
            initSheet();
        }

        const getColor = function(index){
            return state.colors[index%2];
        }

        const clickClass = function(item){
            if(item.finished){
                state.currentItem = item;
                state.showSheet = true;
            }else{
                router.push({name: 'rollCall',params:{id:item.id}});
            }
            
        }
        const selectSheet = function(event){
            if(event.id == 1){//班级评级页面
                router.push({name: 'ClassEvaluation',params:{id:state.currentItem.id}});
            }else if(event.id == 2){//个人评价页面
                router.push({name: 'rollCall',params:{id:state.currentItem.id}});
            }else if(event.id == 3){//作业上传页面
                router.push({name: 'UploadHomeWork',params:{id:state.currentItem.id}});
            }
        }

        const clickDate = function(){
            state.showDate = true;
        }

        const chooseDate = function(date){
            state.currentDate = date;
            state.dayStr = common.getFormatDate(state.currentDate);
            state.showDate = false;
            getLesson();
        }
        
        const getLesson = async()=>{
            var page = new Page(minRow);
            state.loading=true,
            state.finished=false,
            state.lessonList=[];

            var lesson = await request.get(`/lesson/?class_date=${state.currentDate.format('yyyy-MM-dd')}`);
            if(minRow>lesson.data.length){
                state.finished = true;
            }
            state.lessonList = page.setList(lesson.data);
        }

        const onLoad = function(){
            page.addInList(state.lessonList);
            if(page.toTail){
                state.finished = true;
            }  
        }

        const logout = function(){
            Dialog.confirm({
                title:state.lang.exit_login,
                confirmButtonText:state.lang.determine,
                cancelButtonText:state.lang.cancel,
            }).then(() => {
                common.removeLocal('token');
                router.push({name: 'Login'});
            });
        }

        const createLesson = function(){
            router.push({name: 'CourseList'});
        }

        getLesson();

        return {
            ...toRefs(state),
            common,
            toggleLanguage,
            getColor,
            clickClass,
            logout,
            chooseDate,
            clickDate,
            createLesson,
            onLoad,
            selectSheet
        };
    }
}
</script>

<style scoped>
.marginLeft{
    margin-left:20px;
}
.marginTop{
    margin-top:20px;
}
.LessonList{
   width: 100%;
   height:100%; 
   background:#F6F6F6;
}

.day{
    background-color: #F2EAE2;
    color: #D4AB85;
    height:60px;
    line-height: 60px;
    padding:0 20px;
    font-size: 28px;
    font-weight: 600;
    text-align: right;
}


.content{
    font-size: 28px; 
    width: 100%;
    height:calc(100% - 160px - 100px);
    overflow-y:auto;
}

footer{
    height: 100px;
    background-color: #fff;
    width: 100%;
}
.icon{
    width:28px;
}

.nullList{
    width:60%;
    margin:0 auto;
}

.no_courses_page{
    padding-top:40%;
}
.no_courses{
    color: #C4C7D8;
    font-size: 28px;
}
</style>